/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Age {
  Adult = "Adult",
  Youth = "Youth",
}

export enum AmaOrderBy {
  CreatedAt = "CreatedAt",
  Price = "Price",
  StoreName = "StoreName",
}

export enum AmaOrderStatus {
  Accepted = "Accepted",
  Completed = "Completed",
  Declined = "Declined",
  Pending = "Pending",
  Processing = "Processing",
}

export enum AmaRequestOrderBy {
  CreatedAt = "CreatedAt",
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  DateToResponse = "DateToResponse",
  DiscountAmount = "DiscountAmount",
  Id = "Id",
  Instructions = "Instructions",
  OrderStatus = "OrderStatus",
  Price = "Price",
  ReceiverEmail = "ReceiverEmail",
}

export enum AmaStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum AmaType {
  Ama = "Ama",
  Shoutout = "Shoutout",
}

/**
 * price sorts by Order.subtotal
 */
export enum AnyProductSalesOrderBy {
  createdAt = "createdAt",
  customerEmail = "customerEmail",
  customerName = "customerName",
  rawCreatedAt = "rawCreatedAt",
  status = "status",
  subtotal = "subtotal",
  totalStoreRevenues = "totalStoreRevenues",
  type = "type",
}

export enum ArticleStatus {
  Active = "Active",
  Disabled = "Disabled",
}

export enum ArticleStoreOrderBy {
  createdAt = "createdAt",
  status = "status",
}

export enum AuctionStatus {
  Finished = "Finished",
  Live = "Live",
  Scheduled = "Scheduled",
}

export enum AutoGeneratedLogoStatus {
  approved = "approved",
  rejected = "rejected",
}

export enum AutoGeneratedLogoType {
  initials = "initials",
  initialsFlagCenter = "initialsFlagCenter",
  initialsFlagRightSide = "initialsFlagRightSide",
  jersey = "jersey",
  nickname = "nickname",
  signature = "signature",
  signatureBaseball1RightSide = "signatureBaseball1RightSide",
  signatureBaseball2RightSide = "signatureBaseball2RightSide",
  signatureBaseball3RightSide = "signatureBaseball3RightSide",
  signatureBaseball4RightSide = "signatureBaseball4RightSide",
  signatureBasketball1RightSide = "signatureBasketball1RightSide",
  signatureBasketball2RightSide = "signatureBasketball2RightSide",
  signatureBasketball3RightSide = "signatureBasketball3RightSide",
  signatureBasketball4RightSide = "signatureBasketball4RightSide",
  signatureBasketball5RightSide = "signatureBasketball5RightSide",
  signatureBiking1RightSide = "signatureBiking1RightSide",
  signatureBiking2RightSide = "signatureBiking2RightSide",
  signatureBoxing1RightSide = "signatureBoxing1RightSide",
  signatureBoxing2RightSide = "signatureBoxing2RightSide",
  signatureBoxing3RightSide = "signatureBoxing3RightSide",
  signatureFootball1RightSide = "signatureFootball1RightSide",
  signatureFootball2RightSide = "signatureFootball2RightSide",
  signatureFootball3RightSide = "signatureFootball3RightSide",
  signatureFootball4RightSide = "signatureFootball4RightSide",
  signatureFootball5RightSide = "signatureFootball5RightSide",
  signatureFootball6RightSide = "signatureFootball6RightSide",
  signatureFootball7RightSide = "signatureFootball7RightSide",
  signatureGolf1RightSide = "signatureGolf1RightSide",
  signatureGolf2RightSide = "signatureGolf2RightSide",
  signatureHockey2RightSide = "signatureHockey2RightSide",
  signatureHockey3RightSide = "signatureHockey3RightSide",
  signatureHockey4RightSide = "signatureHockey4RightSide",
  signatureHockey5RightSide = "signatureHockey5RightSide",
  signatureHockey6RightSide = "signatureHockey6RightSide",
  signatureMma1RightSide = "signatureMma1RightSide",
  signatureMma2RightSide = "signatureMma2RightSide",
  signatureMma3RightSide = "signatureMma3RightSide",
  signatureMotorracing1RightSide = "signatureMotorracing1RightSide",
  signatureMotorracing2RightSide = "signatureMotorracing2RightSide",
  signatureSkateboarding1RightSide = "signatureSkateboarding1RightSide",
  signatureSkateboarding2RightSide = "signatureSkateboarding2RightSide",
  signatureSoccer1RightSide = "signatureSoccer1RightSide",
  signatureSoccer2RightSide = "signatureSoccer2RightSide",
  signatureSoccer3RightSide = "signatureSoccer3RightSide",
  signatureSoccer4RightSide = "signatureSoccer4RightSide",
  signatureSoccer5RightSide = "signatureSoccer5RightSide",
  signatureSurfing1RightSide = "signatureSurfing1RightSide",
  signatureSurfing2RightSide = "signatureSurfing2RightSide",
  signatureTennis1RightSide = "signatureTennis1RightSide",
  signatureTennis2RightSide = "signatureTennis2RightSide",
}

export enum AutoGeneratedLogosCombinedStatus {
  failed = "failed",
  notReady = "notReady",
  ready = "ready",
}

export enum AutoSocialMediaPackStatus {
  failed = "failed",
  generating = "generating",
  missing = "missing",
  ready = "ready",
}

export enum AutoSocialPackImageStatus {
  completed = "completed",
  failed = "failed",
  inProgress = "inProgress",
}

export enum BlockType {
  Ban = "Ban",
  Kick = "Kick",
  Mute = "Mute",
  Unmute = "Unmute",
}

export enum CampaignMediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum CampaignOfferGenderFilter {
  NA = "NA",
  female = "female",
  male = "male",
  other = "other",
  preferNotToSay = "preferNotToSay",
}

export enum CampaignOfferStatus {
  ACCEPTED = "ACCEPTED",
  APPLIED = "APPLIED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  INVITED = "INVITED",
  PROPOSED = "PROPOSED",
  SUBMITTED = "SUBMITTED",
}

export enum CampaignOffersOrderBy {
  appliedAt = "appliedAt",
  approvedAt = "approvedAt",
  createdAt = "createdAt",
  declinedAt = "declinedAt",
  id = "id",
  name = "name",
  socialFollowing = "socialFollowing",
  status = "status",
  submittedAt = "submittedAt",
}

export enum CampaignSmPlatform {
  IG = "IG",
  TT = "TT",
}

export enum CampaignStatus {
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
  DETAIL_FILLED = "DETAIL_FILLED",
  ON_REVIEW = "ON_REVIEW",
  PAID_AND_ACTIVE = "PAID_AND_ACTIVE",
  PAID_AND_BUDGET_FULFILLED = "PAID_AND_BUDGET_FULFILLED",
}

export enum CampaignTaskType {
  IG_LIVE = "IG_LIVE",
  IG_POST = "IG_POST",
  IG_REEL = "IG_REEL",
  IG_STORY = "IG_STORY",
  TT_VIDEO = "TT_VIDEO",
}

export enum CampaignType {
  Social = "Social",
}

export enum DesignRequestStatus {
  Accepted = "Accepted",
  Archived = "Archived",
  AutoAccepted = "AutoAccepted",
  AwaitingApproval = "AwaitingApproval",
  InProgress = "InProgress",
  OnReview = "OnReview",
  Rejected = "Rejected",
  Todo = "Todo",
}

export enum DesignRequestType {
  LogoAudit = "LogoAudit",
  LogoCreation = "LogoCreation",
  MerchCreation = "MerchCreation",
  MerchCreationWithLogo = "MerchCreationWithLogo",
}

export enum DesignRequestsOrderBy {
  assignDate = "assignDate",
  createdAt = "createdAt",
  designRate = "designRate",
  designRequestId = "designRequestId",
  designRequestType = "designRequestType",
  designerName = "designerName",
  designerStatus = "designerStatus",
  dueDate = "dueDate",
  dueDatePriority = "dueDatePriority",
  status = "status",
  storeName = "storeName",
}

export enum ExperienceOrderBy {
  createdAt = "createdAt",
  price = "price",
  title = "title",
}

export enum ExperienceRequestOrderBy {
  CreatedAt = "CreatedAt",
  CustomerEmail = "CustomerEmail",
  CustomerName = "CustomerName",
  Email = "Email",
  Id = "Id",
  Price = "Price",
  RequestStatus = "RequestStatus",
  StoreName = "StoreName",
}

export enum ExperienceRequestStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Pending = "Pending",
  Purchased = "Purchased",
  Refunded = "Refunded",
}

export enum ExperienceSalesOrderBy {
  CreatedAt = "CreatedAt",
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  Id = "Id",
  PaidAt = "PaidAt",
  RequestedProfit = "RequestedProfit",
  Status = "Status",
  Title = "Title",
}

export enum ExperienceStatus {
  Active = "Active",
  Inactive = "Inactive",
  Sold = "Sold",
}

/**
 * Youth is deprecated
 */
export enum Gender {
  Female = "Female",
  Male = "Male",
  Unisex = "Unisex",
  Youth = "Youth",
}

export enum GenderInputFilter {
  Female = "Female",
  Male = "Male",
  Youth = "Youth",
}

export enum HashtagsFilterOrderBy {
  name = "name",
}

export enum MediaJobStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
}

export enum MediaPostStatus {
  published = "published",
  scheduled = "scheduled",
  scheduledInstant = "scheduledInstant",
}

export enum MediaPostsOrderBy {
  publishedAt = "publishedAt",
}

export enum MemorabiliaFulfillment {
  Athlete = "Athlete",
  Millions = "Millions",
}

export enum MemorabiliaOrderBy {
  createdAt = "createdAt",
  inappropriateImageCount = "inappropriateImageCount",
  price = "price",
  title = "title",
}

export enum MemorabiliaProductType {
  Memorabilia = "Memorabilia",
  Product = "Product",
}

export enum MemorabiliaSaleMethod {
  auction = "auction",
  standard = "standard",
}

export enum MemorabiliaStatus {
  Active = "Active",
  Expired = "Expired",
  Removed = "Removed",
  Sold = "Sold",
}

/**
 * Used as a generic status for SubOrder
 */
export enum MerchOrderStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
  Processing = "Processing",
}

export enum MerchProductOrderBy {
  Date = "Date",
  Price = "Price",
  StoreName = "StoreName",
  Title = "Title",
}

export enum MerchProductStatus {
  Active = "Active",
  Inactive = "Inactive",
  MockupsFailed = "MockupsFailed",
  PendingMockups = "PendingMockups",
}

export enum MerchProductType {
  Hat = "Hat",
  Joggers = "Joggers",
  ManHoodie = "ManHoodie",
  ManJoggers = "ManJoggers",
  ManRashGuard = "ManRashGuard",
  ManTShirt = "ManTShirt",
  ManTankTop = "ManTankTop",
  WomanHoodie = "WomanHoodie",
  WomanJoggers = "WomanJoggers",
  WomanRashGuard = "WomanRashGuard",
  WomanTShirt = "WomanTShirt",
  WomanTankTop = "WomanTankTop",
  YouthTShirt = "YouthTShirt",
}

export enum MerchType {
  Hat = "Hat",
  Hoodie = "Hoodie",
  Joggers = "Joggers",
  RashGuard = "RashGuard",
  TShirt = "TShirt",
}

export enum MyTipsAndOrdersSalesOrderBy {
  CustomerEmail = "CustomerEmail",
  CustomerFirstName = "CustomerFirstName",
  Discount = "Discount",
  Earnings = "Earnings",
  PaidAt = "PaidAt",
  Type = "Type",
}

export enum OrderListOrderBy {
  Address = "Address",
  Discount = "Discount",
  Id = "Id",
  OrderDate = "OrderDate",
  TotalPrice = "TotalPrice",
}

/**
 * ProductOrder can be either Product or Memorabilia
 */
export enum OrderType {
  MerchOrder = "MerchOrder",
  ProductOrder = "ProductOrder",
}

export enum OrganizationType {
  AssociationLeagueTeam = "AssociationLeagueTeam",
  EventOrganizer = "EventOrganizer",
  Gym = "Gym",
  Other = "Other",
}

export enum PlacementTypes {
  back = "back",
  embroidery_back = "embroidery_back",
  embroidery_front = "embroidery_front",
  embroidery_front_large = "embroidery_front_large",
  embroidery_left = "embroidery_left",
  embroidery_right = "embroidery_right",
  front = "front",
  leg_back_left = "leg_back_left",
  leg_back_right = "leg_back_right",
  leg_front_left = "leg_front_left",
  leg_front_right = "leg_front_right",
  pocket = "pocket",
  sleeve_left = "sleeve_left",
  sleeve_right = "sleeve_right",
}

export enum ProductSalesType {
  memorabilia = "memorabilia",
  merch = "merch",
  product = "product",
}

export enum ProductTypesType {
  Ama = "Ama",
  Experience = "Experience",
  Merch = "Merch",
  Product = "Product",
  Shoutout = "Shoutout",
  Stream = "Stream",
}

export enum PromoCodesChargeSubject {
  Platform = "Platform",
  Store = "Store",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StoreAthleticLevel {
  Amateur = "Amateur",
  CollegeUniversity = "CollegeUniversity",
  OlympicNational = "OlympicNational",
  Other = "Other",
  Professional = "Professional",
  RetiredProfessional = "RetiredProfessional",
}

export enum StoreCareerStatus {
  Active = "Active",
  Retired = "Retired",
}

export enum StoreGender {
  female = "female",
  male = "male",
  other = "other",
  preferNotToSay = "preferNotToSay",
}

export enum StoreStatus {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum StoresOrderBy {
  activatedAt = "activatedAt",
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
  storeName = "storeName",
  totalSales = "totalSales",
}

export enum StreamIntervalEnum {
  biweekly = "biweekly",
  monthly = "monthly",
  weekly = "weekly",
}

export enum StreamOrderBy {
  Description = "Description",
  Name = "Name",
  Price = "Price",
  RequestedPrice = "RequestedPrice",
  ScheduleDate = "ScheduleDate",
  ScheduleDateStrict = "ScheduleDateStrict",
  StoreName = "StoreName",
  StreamStatus = "StreamStatus",
}

export enum StreamOrderSalesOrderBy {
  CreatedAt = "CreatedAt",
  CustomerFirstName = "CustomerFirstName",
  DiscountAmount = "DiscountAmount",
  PaidAt = "PaidAt",
  RequestedProfit = "RequestedProfit",
}

export enum StreamOrderSortBy {
  CreatedAt = "CreatedAt",
  Description = "Description",
  DiscountAmount = "DiscountAmount",
  OrderStatus = "OrderStatus",
  Price = "Price",
  ScheduleDate = "ScheduleDate",
  StoreName = "StoreName",
  StreamName = "StreamName",
  StreamStatus = "StreamStatus",
}

export enum StreamOrderStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Pending = "Pending",
  Processing = "Processing",
}

export enum StreamParticipantsOrderBy {
  createdAt = "createdAt",
  firstName = "firstName",
  lastName = "lastName",
}

export enum StreamPriceCategory {
  Free = "Free",
  Paid = "Paid",
}

export enum StreamSalesOrderBy {
  Earnings = "Earnings",
  Name = "Name",
  OrderCnt = "OrderCnt",
  ScheduleDate = "ScheduleDate",
  TipCnt = "TipCnt",
}

export enum StreamStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Ended = "Ended",
  Interrupted = "Interrupted",
  Paused = "Paused",
  Scheduled = "Scheduled",
}

export enum StripeIntentStatus {
  canceled = "canceled",
  processing = "processing",
  requires_action = "requires_action",
  requires_capture = "requires_capture",
  requires_confirmation = "requires_confirmation",
  requires_payment_method = "requires_payment_method",
  succeeded = "succeeded",
}

export enum UserBlackListOrderBy {
  BlockType = "BlockType",
  CreatedAt = "CreatedAt",
}

/**
 * Gym is deprecated and substituted with Organization
 */
export enum UserRole {
  Admin = "Admin",
  Agency = "Agency",
  Association = "Association",
  Athlete = "Athlete",
  Charity = "Charity",
  Coach = "Coach",
  ContentCreator = "ContentCreator",
  Customer = "Customer",
  Designer = "Designer",
  Guest = "Guest",
  Gym = "Gym",
  ManagementFirm = "ManagementFirm",
  Moderator = "Moderator",
  Organization = "Organization",
  Team = "Team",
  Trainer = "Trainer",
  VideoEditor = "VideoEditor",
}

export enum VideoEditRequestStatus {
  Accepted = "Accepted",
  Archived = "Archived",
  AutoAccepted = "AutoAccepted",
  InProgress = "InProgress",
  OnReview = "OnReview",
  PartialyRejected = "PartialyRejected",
  Rejected = "Rejected",
}

export enum VideoEditRequestSubmissionStatus {
  Approved = "Approved",
  AutoApproved = "AutoApproved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum VideoEditRequestsStoreOrderBy {
  assignedEditor = "assignedEditor",
  createdAt = "createdAt",
  dueDate = "dueDate",
  status = "status",
  storeName = "storeName",
  videoEditRequestType = "videoEditRequestType",
  videoEditorName = "videoEditorName",
}

export interface AcceptAllVideoEditRequestByStoreInput {
  feedback?: string | null;
  rate: number;
  storeId?: string | null;
  videoEditRequestId: string;
}

export interface AcceptInterviewInviteInput {
  interviewId: string;
}

export interface AcceptMerchDesignInput {
  designRequestId: string;
  feedback?: string | null;
  rate?: number | null;
}

export interface AcceptOptInMerchInput {
  blueprintId: string;
}

export interface AcceptSignedMemorabiliaInput {
  blueprintId: string;
}

export interface AcceptVideoEditByStoreRequestInput {
  editedVideoId: string;
  feedback?: string | null;
  rate: number;
  storeId?: string | null;
  videoEditRequestId: string;
}

export interface AddParticipantsToStreamInput {
  invite: string;
  streamId: string;
}

export interface AllHashtagsFilterInput {
  direction?: SortDirection | null;
  generalOnly?: boolean | null;
  hashtagNames?: string[] | null;
  keyword?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: HashtagsFilterOrderBy | null;
  sportIds?: number[] | null;
}

export interface AmaFilterInput {
  daysToResponse?: FromToFilter | null;
  direction?: SortDirection | null;
  hashtagIds?: number[] | null;
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaOrderBy | null;
  price?: FromToFilter | null;
  sports?: number[] | null;
  status?: AmaStatus | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeStatus?: StoreStatus | null;
}

export interface AmaItemCreateInput {
  daysToResponse: number;
  requestedPrice: number;
  status: AmaStatus;
  type: AmaType;
}

export interface AmaItemEditInput {
  daysToResponse: number;
  id?: string | null;
  requestedPrice: number;
  status: AmaStatus;
  type: AmaType;
}

export interface AmaRequestCreateInput {
  amaId: string;
  email?: string | null;
  from?: string | null;
  instructions?: string | null;
  promocodeId?: string | null;
  to?: string | null;
}

export interface AmaRequestCreatePresignedUrlInput {
  id: string;
}

export interface AmaRequestDeclineInput {
  declineReason: string;
  id: string;
}

export interface AmaRequestListFilter {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AmaRequestOrderBy | null;
}

export interface AmaRequestSubmitVideoInput {
  id: string;
  videoKey: string;
}

export interface AmaVideoInput {
  area: VideoCroppedArea;
  type?: string | null;
  videoKey: string;
}

export interface AmasCreateInput {
  amas: AmaItemCreateInput[];
  video?: VideoCroppedInput | null;
  videoV2?: AmaVideoInput | null;
}

export interface AmasEditInput {
  amas?: AmaItemEditInput[] | null;
  deleteVideo?: boolean | null;
  video?: VideoCroppedInput | null;
  videoV2?: AmaVideoInput | null;
}

export interface ApplyForCampaignOfferInput {
  addressId?: string | null;
  offerId: string;
}

export interface ApproveAutoGeneratedMerchInput {
  types: AutoGeneratedLogoType[];
}

export interface ArticleBySlugInput {
  articleSlug: string;
  storeSlug: string;
}

export interface ArticleStoreFilterInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ArticleStoreOrderBy | null;
  statuses?: ArticleStatus[] | null;
  storeId?: string | null;
  storeSlug?: string | null;
}

export interface AttachThankYouVideoInput {
  orderId: string;
  productType: ProductTypesType;
  storeId?: string | null;
  video: any;
}

export interface AttachThankYouVideoStreamInput {
  storeId?: string | null;
  streamId: string;
  video: any;
}

export interface AttachThankYouVideoTemplateInput {
  orderId: string;
  productType: ProductTypesType;
  storeId?: string | null;
  thankYouVideoId: string;
}

export interface AttachThankYouVideoTemplateStreamInput {
  storeId?: string | null;
  streamId: string;
  thankYouVideoId: string;
}

export interface AuctionFromToInput {
  onAuctionFrom: any;
  onAuctionTo: any;
}

export interface AvailableSlotsDateRangeInput {
  from: any;
  to: any;
}

export interface BookInterviewSlotInput {
  interviewId: string;
  startDateUtc: any;
}

export interface CampaignOfferSubmissionInput {
  imageKey?: string | null;
  link?: string | null;
  type: CampaignTaskType;
}

export interface ChangePasswordInput {
  newPassword: string;
  oldPassword?: string | null;
}

export interface CityFilterInput {
  countryCode: string;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
}

export interface CompleteMultipartUploadInput {
  fileId: string;
  fileKey: string;
  parts: CompleteMultipartUploadPartInput[];
}

export interface CompleteMultipartUploadPartInput {
  ETag: string;
  PartNumber: number;
}

export interface CompleteStripePaymentInput {
  stripeIntentID: string;
}

export interface CountryFilterInput {
  code?: string | null;
  name?: string | null;
}

export interface CreateAddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  company?: string | null;
  country?: string | null;
  countryCode: string;
  email?: string | null;
  firstName: string;
  isDefault?: boolean | null;
  lastName: string;
  phone?: string | null;
  phoneNumberE164?: string | null;
  state?: string | null;
  stateCode?: string | null;
  userId?: string | null;
  zipCode: string;
}

export interface CreateCampaignSubmissionFileInput {
  name: string;
}

export interface CreateCampaignSubmissionFilesInput {
  files: CreateCampaignSubmissionFileInput[];
  offerId: string;
}

export interface CreateDesignImageExamplePresignedUrlInput {
  numberOfImages: number;
}

export interface CreateExperienceImagePresignedUrlInput {
  numberOfImages: number;
}

export interface CreateImagePostInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  image: CreatePostImageInput;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  title: string;
}

export interface CreateMemorabiliaImagePresignedUrl {
  numberOfImages: number;
}

export interface CreateMemorabiliaInput {
  addressId?: string | null;
  auctionDateRange?: AuctionFromToInput | null;
  comment?: string | null;
  customsDescription?: string | null;
  description?: string | null;
  fulfillment: MemorabiliaFulfillment;
  hashtagInputs?: HashtagInput[] | null;
  height?: number | null;
  images: MemorabiliaImageInput[];
  length?: number | null;
  memorabiliaProductType: MemorabiliaProductType;
  numberOfUnits: number;
  requestedPrice: number;
  shipmentCategory: string;
  storeId: string;
  title: string;
  weight?: number | null;
  width?: number | null;
}

export interface CreateMentionInput {
  storeId?: string | null;
  unregisteredContactId?: string | null;
}

export interface CreateMerchDesignInput {
  designColorPaletteIds: number[];
  designCustomColor?: string | null;
  designExtraInfo?: string | null;
  designName: string;
  designSampleIds: number[];
  designStyleSettingsInput: DesignStyleSettingsInput;
  designTypeIds: number[];
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
}

export interface CreateMerchDesignLogoInput {
  key: string;
}

export interface CreateMerchDesignWithLogoInput {
  designExtraInfo?: string | null;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
  storeLogos: CreateMerchDesignLogoInput[];
}

export interface CreateMerchOrderItem {
  amount: number;
  memorabiliaId?: string | null;
  oldVariantId?: number | null;
  variantId?: number | null;
}

export interface CreateMerchOrderItemInput {
  items: CreateMerchOrderItem[];
}

export interface CreateMerchProductInput {
  customImages?: MerchCustomImageInput[] | null;
  designRequestId?: string | null;
  finalMerchStatus?: MerchProductStatus | null;
  hashtagInputs?: HashtagInput[] | null;
  merchProductType: MerchProductType;
  printfulVariantIds: number[];
  productPrintFiles: ProductPrintFileInput[];
  requestedProfit: number;
  stitches?: string | null;
  storeId?: string | null;
  threads?: string | null;
  title: string;
}

export interface CreateMerchProductV2Input {
  customImageSets?: MerchProductCustomImageSetInput[] | null;
  hashtagInputs?: HashtagInput[] | null;
  printFileSets: MerchProductPrintFileSetInput[];
  requestedProfit: number;
  title: string;
  type: MerchType;
  variantSets: MerchProductVariantSet[];
}

export interface CreateOrderInput {
  addressId: string;
  billingAddressId: string;
  paymentMethodId?: string | null;
  promocodeId?: string | null;
}

export interface CreatePostImageInput {
  key: string;
}

export interface CreatePostImagePresignedUrlV2FileInput {
  contentType: string;
  ext: string;
}

export interface CreatePostImagePresignedUrlV2Input {
  files: CreatePostImagePresignedUrlV2FileInput[];
}

export interface CreatePostVideoInput {
  key: string;
}

export interface CreateStoreExampleImageInput {
  key: string;
}

export interface CreateStoreExperienceInput {
  comment?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  images: ExperienceImageInput[];
  numberOfUnits?: number | null;
  requestedPrice: number;
  title: string;
}

export interface CreateThankYouVideoTemplateInput {
  productType: ProductTypesType;
  storeId?: string | null;
  video: any;
}

export interface CreateVariantPrintfilePresignedUrlsInput {
  files: VariantPrintfilePresignedUrlInput[];
}

export interface CreateVerificationVideoInput {
  file: any;
  type?: string | null;
}

export interface CreateVideoPostInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  thumbnailKey?: string | null;
  title: string;
  video: CreatePostVideoInput;
}

export interface DateV2Input {
  tz?: string | null;
  utc: any;
}

export interface DeleteMediaPostInput {
  id: string;
}

export interface DeleteMemorabiliaInput {
  id: string;
  storeId?: string | null;
}

export interface DeleteStoreExperienceInput {
  id: string;
}

export interface DeleteThankYouVideoTemplateInput {
  storeId?: string | null;
  thankYouVideoId: string;
}

export interface DesignRequestsFilterInput {
  designRequestId?: string | null;
  designRequestIds?: string[] | null;
  designRequestStatuses?: DesignRequestStatus[] | null;
  designRequestType?: DesignRequestType | null;
  designRequestTypes?: DesignRequestType[] | null;
  designerIds?: string[] | null;
  designerName?: string | null;
  direction?: SortDirection | null;
  escalatedToId?: string | null;
  isAwaitingAthleteApproval?: boolean | null;
  isInProgress?: boolean | null;
  isOverdue?: boolean | null;
  isUnassigned?: boolean | null;
  limit?: number | null;
  managedById?: string | null;
  offset?: number | null;
  orderBy?: DesignRequestsOrderBy | null;
  storeIds?: string[] | null;
  storeName?: string | null;
}

export interface DesignStyleSettingsInput {
  abstractLiteral?: number | null;
  classicModern?: number | null;
  economicalLuxurious?: number | null;
  feminineMasculine?: number | null;
  geometricOrganic?: number | null;
  matureYouthful?: number | null;
  playfulSophisticated?: number | null;
}

export interface DimensionsInput {
  height: number;
  width: number;
}

export interface EditImagePostInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  image: EditPostImageInput;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  title: string;
}

export interface EditMerchDesignInput {
  designColorPaletteIds?: number[] | null;
  designCustomColor?: string | null;
  designExtraInfo?: string | null;
  designName?: string | null;
  designRequestId: string;
  designSampleIds?: number[] | null;
  designStyleSettingsInput?: DesignStyleSettingsInput | null;
  designTypeIds?: number[] | null;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
}

export interface EditMerchDesignWithLogoInput {
  designExtraInfo?: string | null;
  designRequestId: string;
  merchProductTypes?: MerchProductType[] | null;
  merchTypes?: MerchType[] | null;
  socialMediaURL?: string | null;
  storeExampleImages?: CreateStoreExampleImageInput[] | null;
  storeLogos?: CreateMerchDesignLogoInput[] | null;
}

export interface EditMerchProductV2Input {
  customImageSets?: MerchProductCustomImageSetInput[] | null;
  hashtagInputs?: HashtagInput[] | null;
  id: number;
  printFileSets?: MerchProductPrintFileSetInput[] | null;
  requestedProfit: number;
  title: string;
  type: MerchType;
  variantSets?: MerchProductVariantSet[] | null;
}

export interface EditPostImageInput {
  id?: string | null;
  key?: string | null;
}

/**
 * Provide either id to keep existing video or a key to upload new video
 */
export interface EditPostVideoInput {
  id?: string | null;
  key?: string | null;
}

export interface EditVideoPostInput {
  body?: string | null;
  buyProductLink?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  mentionsInputs?: CreateMentionInput[] | null;
  scheduled?: ScheduleDateInput | null;
  scheduledV2?: ScheduledPostDateV2Input | null;
  thumbnailKey?: string | null;
  title: string;
  video: EditPostVideoInput;
}

export interface EstimateAuctionDeliveryInput {
  addressId?: string | null;
  auctionId: string;
  bidAmount: number;
}

export interface EstimateOrderInput {
  addressId: string;
  billingAddressId: string;
  promocodeId?: string | null;
}

export interface ExperienceFilterInput {
  description?: string | null;
  direction?: SortDirection | null;
  id?: string | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ExperienceOrderBy | null;
  price?: FromToFilter | null;
  searchTerm?: string | null;
  sports?: number[] | null;
  statuses?: ExperienceStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
  title?: string | null;
}

export interface ExperienceImageInput {
  experienceImageFileKey: string;
  isMainImage?: boolean | null;
}

export interface ExperiencePriceEstimateInput {
  requestedPrice: number;
}

export interface ExperienceRequestCreateInput {
  email?: string | null;
  experienceId: string;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  quantity: number;
}

export interface ExperienceRequestListFilter {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ExperienceRequestOrderBy | null;
}

export interface ExperienceSalesInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerEmail?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  experienceId?: string | null;
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: ExperienceSalesOrderBy | null;
  statuses?: ExperienceRequestStatus[] | null;
  storeId?: string | null;
  storeName?: string | null;
  title?: string | null;
}

export interface FindOrderInput {
  email: string;
  orderId: number;
}

export interface FollowStoresInput {
  storeIds: string[];
}

export interface FromToDatesFilter {
  from?: any | null;
  to?: any | null;
}

export interface FromToFilter {
  from?: number | null;
  to?: number | null;
}

export interface GetAddressesInput {
  userId: string;
}

export interface GetAffiliateStreamProductsInput {
  streamId: string;
}

export interface GetAnyProductSalesInput {
  createdAtDateRange?: FromToDatesFilter | null;
  customerEmail?: string | null;
  customerId?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: AnyProductSalesOrderBy | null;
  orderId?: string | null;
  orderIdStrict?: number | null;
  storeId?: string | null;
  subOrderStatuses?: MerchOrderStatus[] | null;
  subtotalRange?: FromToFilter | null;
  totalStoreRevenuesRange?: FromToFilter | null;
  types?: ProductSalesType[] | null;
}

export interface GetInterviewAvailableSlotsInput {
  dateRange: AvailableSlotsDateRangeInput;
  interviewId: string;
}

export interface GetInterviewScheduleInput {
  interviewId: string;
}

export interface GetMediaPostInput {
  id?: string | null;
  slug?: string | null;
  storeSlug?: string | null;
}

export interface GetMediaPostsInput {
  direction?: SortDirection | null;
  ids?: string[] | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MediaPostsOrderBy | null;
  searchTerm?: string | null;
  statuses?: MediaPostStatus[] | null;
  storeId?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
}

export interface GetMerchProductInput {
  id?: number | null;
  slug?: string | null;
  storeSlug?: string | null;
}

export interface GetMerchProductsByIds {
  ids?: number[] | null;
}

export interface GetMerchProductsInput {
  color?: MerchProductColor[] | null;
  createdByRoles?: UserRole[] | null;
  direction?: SortDirection | null;
  gender?: Gender[] | null;
  genderV2?: GenderInputFilter[] | null;
  ids?: number[] | null;
  isCreatedByAdmin?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MerchProductOrderBy | null;
  price?: FromToFilter | null;
  searchTerm?: string | null;
  size?: string[] | null;
  sports?: number[] | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  title?: string | null;
  type?: MerchType[] | null;
}

export interface GetMyCampaignOfferByCampaignInput {
  campaignId: string;
}

export interface GetMyCampaignOffersInput {
  ageRange?: FromToFilter | null;
  appliedAtRange?: FromToDatesFilter | null;
  approvedAtRange?: FromToDatesFilter | null;
  countries?: string[] | null;
  createdAtRange?: FromToDatesFilter | null;
  declinedAtRange?: FromToDatesFilter | null;
  direction?: SortDirection | null;
  genders?: CampaignOfferGenderFilter[] | null;
  isProductSent?: boolean | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: CampaignOffersOrderBy | null;
  socialFollowingRange?: FromToFilter | null;
  sports?: string[] | null;
  states?: string[] | null;
  statuses?: CampaignOfferStatus[] | null;
  submittedAtRange?: FromToDatesFilter | null;
  totalFeeRange?: FromToFilter | null;
}

export interface GetNumberOfFollowersInput {
  storeId: string;
}

export interface GetOrdersInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: OrderListOrderBy | null;
  userId?: string | null;
}

export interface GetPrProductsInput {
  type: MerchType;
}

export interface GetPromoCodeInput {
  amaId?: string | null;
  customerId?: string | null;
  id?: string | null;
  merchIds?: number[] | null;
  name?: string | null;
  streamId?: string | null;
}

export interface GetSportsInput {
  name?: string | null;
  storeRoles?: UserRole[] | null;
}

export interface GetStoreMemorabiliaInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MemorabiliaOrderBy | null;
  storeId?: string | null;
  storeSlug?: string | null;
}

export interface GetStoreMerchProductsInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MerchProductOrderBy | null;
  storeId?: string | null;
  storeSlug?: string | null;
}

export interface GetStoreStreamsInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StreamOrderBy | null;
  scheduleDate?: FromToDatesFilter | null;
  storeId?: string | null;
  storeSlug?: string | null;
  streamStatus?: StreamStatus[] | null;
}

export interface GetStreamInput {
  id?: string | null;
  slug?: string | null;
  storeId?: string | null;
  storeSlug?: string | null;
}

export interface GetStreamUsersInput {
  id?: string | null;
  limit?: number | null;
  offset?: number | null;
  slug?: string | null;
}

export interface GetThankYouVideByOrderIdInput {
  orderId: string;
  productType?: ProductTypesType | null;
}

export interface GetThankYouVideoTemplateInput {
  productType: ProductTypesType;
  storeId?: string | null;
}

export interface GetUserBlackListInput {
  direction?: SortDirection | null;
  orderBy?: UserBlackListOrderBy | null;
  streamId?: string | null;
  userId: string;
}

export interface GoogleSignInInput {
  code: string;
  redirectUrl?: string | null;
}

export interface HashtagInput {
  name: string;
}

export interface HideStreamInput {
  id: string;
}

export interface InitAuthTwitterInput {
  redirectUrl: string;
}

export interface InitTiktokAuthInput {
  redirectUrl: string;
}

export interface InitVideoPostMultipartUploadInput {
  contentType: string;
  fileExt: string;
  partCnt: number;
}

export interface IsUserFollowingStoreInput {
  storeId: string;
}

export interface MemorabiliaFilterInput {
  auctionStatuses?: AuctionStatus[] | null;
  createdByRoles?: UserRole[] | null;
  description?: string | null;
  direction?: SortDirection | null;
  fulfillment?: MemorabiliaFulfillment | null;
  id?: string | null;
  ids?: string[] | null;
  isCreatedByAdmin?: boolean | null;
  isMarkedInappropriate?: boolean | null;
  isOrphanPage?: boolean | null;
  limit?: number | null;
  numberOfUnits?: number | null;
  offset?: number | null;
  orderBy?: MemorabiliaOrderBy | null;
  price?: FromToFilter | null;
  productTypes?: MemorabiliaProductType[] | null;
  requestPrice?: FromToFilter | null;
  saleMethods?: MemorabiliaSaleMethod[] | null;
  searchTerm?: string | null;
  sports?: number[] | null;
  statuses?: MemorabiliaStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatuses?: StoreStatus[] | null;
  title?: string | null;
}

export interface MemorabiliaImageInput {
  isMainImage?: boolean | null;
  memorabiliaImageFileKey: string;
}

export interface MerchCustomImageInput {
  imageFileKey: string;
  isMainImage?: boolean | null;
  position?: number | null;
  printfulVariantIds: number[];
}

export interface MerchCustomImageV2Input {
  isMain: boolean;
  key: string;
}

export interface MerchProductColor {
  color?: string | null;
  colorCode?: string | null;
  colorCode2?: string | null;
}

export interface MerchProductCustomImageSetInput {
  customImages?: MerchCustomImageV2Input[] | null;
  variantSetIndices: number[];
}

export interface MerchProductPrintFileSetInput {
  printFiles: VariantPrintFileInput[];
  variantSetIndices: number[];
}

export interface MerchProductVariantSet {
  age: Age;
  gender: Gender;
  stitches?: string | null;
  threads?: string[] | null;
  variantIds: number[];
}

export interface MyDashboardFilter {
  from?: any | null;
  to?: any | null;
}

export interface MySalesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StreamOrderSalesOrderBy | null;
  storeId?: string | null;
}

export interface MyStreamsFilterInput {
  direction?: SortDirection | null;
  haveSponsor?: boolean | null;
  id?: string | null;
  ids?: string[] | null;
  limit?: number | null;
  moderatorId?: string | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamOrderBy | null;
  price?: FromToFilter | null;
  priceCategories?: StreamPriceCategory[] | null;
  scheduleDate?: FromToDatesFilter | null;
  searchTerm?: string | null;
  slug?: string | null;
  sportIds?: number[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatus?: StoreStatus | null;
  streamStatus?: StreamStatus[] | null;
  tzcodes?: string[] | null;
  withCopies?: boolean | null;
  withDeleted?: boolean | null;
}

export interface OutcomingStreamInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StreamOrderSortBy | null;
}

export interface PasswordResetInput {
  password: string;
  passwordResetToken: string;
}

export interface PlaceBidInput {
  addressId?: string | null;
  amount: number;
  auctionId: string;
}

export interface PrintImageInput {
  printImageId: string;
  userId?: string | null;
}

export interface PrintImageUploadInput {
  designRequestId?: string | null;
  image: any;
  userId?: string | null;
}

export interface ProductPriceEstimateInput {
  changedStock?: number | null;
  fulfillment?: MemorabiliaFulfillment | null;
  numberOfUnits?: number | null;
  productId?: string | null;
  requestedPrice: number;
}

export interface ProductPrintFileEditInputType {
  image?: string | null;
  placementType: PlacementTypes;
  printFilePlacements: ProductPrintFilePlacementInput[];
}

export interface ProductPrintFileInput {
  image: string;
  placementType: PlacementTypes;
  printFilePlacements: ProductPrintFilePlacementInput[];
}

export interface ProductPrintFilePlacementInput {
  angle: number;
  left: number;
  printImageId: string;
  rotatedLeft: number;
  rotatedTop: number;
  scaleX: number;
  scaleY: number;
  top: number;
}

export interface RecoverMemorabiliaInput {
  id: string;
  storeId?: string | null;
}

export interface RecoverStoreExperienceInput {
  id: string;
}

export interface RefreshTokensInput {
  refreshToken: string;
}

export interface RemoveMerchCartItem {
  memorabiliaId?: string | null;
  variantId?: number | null;
}

export interface RemoveMerchCartItemInput {
  items: RemoveMerchCartItem[];
}

export interface RequestHelpInputEmail {
  message?: string | null;
  streamId: string;
}

export interface ReviewActionVideoEditRequestInput {
  editedVideoId: string;
  feedback?: string | null;
  storeId?: string | null;
  videoEditRequestId: string;
}

export interface ScheduleDateInput {
  at: any;
  offset?: number | null;
  tzCode?: string | null;
}

export interface ScheduleInterviewAttemptInput {
  interviewId: string;
}

export interface ScheduledPostDateV2Input {
  tz?: string | null;
  utc: any;
}

export interface SetBrandDealSettingsInput {
  contactEmail?: string | null;
  minFlatPrice?: number | null;
  ownerId?: string | null;
}

export interface SetProductSuborderShippedInput {
  suborderId: string;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface SignOutInput {
  refreshToken: string;
}

export interface SignUpAthleteInput {
  athleticLevel?: StoreAthleticLevel | null;
  birthDate?: string | null;
  careerStatus?: StoreCareerStatus | null;
  city?: string | null;
  collegeId?: string | null;
  country?: string | null;
  email: string;
  facebookFollowing?: number | null;
  facebookLink?: string | null;
  favoriteNumber?: number | null;
  firstName: string;
  gender?: StoreGender | null;
  hometown?: string | null;
  instagramFollowing?: number | null;
  instagramLink?: string | null;
  lastName: string;
  leagueId?: string | null;
  otherCollege?: string | null;
  otherLeague?: string | null;
  otherSport?: string | null;
  otherTeam?: string | null;
  password: string;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  socialMediaLink?: string | null;
  sportId: number;
  state?: string | null;
  teamId?: string | null;
  tiktokFollowing?: number | null;
  tiktokLink?: string | null;
  twitterFollowing?: number | null;
  twitterLink?: string | null;
  youtubeFollowing?: number | null;
  youtubeLink?: string | null;
}

export interface SignUpContentCreatorInput {
  city: string;
  country: string;
  email: string;
  facebookFollowing?: number | null;
  facebookLink?: string | null;
  firstName: string;
  gymRole: string;
  instagramFollowing?: number | null;
  instagramLink?: string | null;
  lastName: string;
  otherSport?: string | null;
  password: string;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  socialMediaLink?: string | null;
  sportId: number;
  state?: string | null;
  storeName: string;
  tiktokFollowing?: number | null;
  tiktokLink?: string | null;
  twitterFollowing?: number | null;
  twitterLink?: string | null;
  website?: string | null;
  youtubeFollowing?: number | null;
  youtubeLink?: string | null;
}

export interface SignUpCustomerInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface SignUpGuestInput {
  email: string;
}

export interface SignUpOrganizationInput {
  city: string;
  country: string;
  email: string;
  facebookFollowing?: number | null;
  facebookLink?: string | null;
  firstName: string;
  gymRole: string;
  instagramFollowing?: number | null;
  instagramLink?: string | null;
  lastName: string;
  organizationType?: OrganizationType | null;
  otherSport?: string | null;
  password: string;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  socialMediaLink?: string | null;
  sportId: number;
  state?: string | null;
  storeName: string;
  tiktokFollowing?: number | null;
  tiktokLink?: string | null;
  twitterFollowing?: number | null;
  twitterLink?: string | null;
  website?: string | null;
  youtubeFollowing?: number | null;
  youtubeLink?: string | null;
}

export interface StartStreamInput {
  dimensions?: DimensionsInput | null;
  id: string;
}

export interface StoreDetailsInput {
  athleticLevel?: StoreAthleticLevel | null;
  avatar?: any | null;
  avatarFileKey?: string | null;
  bio?: string | null;
  birthDate?: string | null;
  careerStatus?: StoreCareerStatus | null;
  city?: string | null;
  collegeId?: string | null;
  country?: string | null;
  cover?: any | null;
  description?: string | null;
  facebookFollowing?: number | null;
  facebookLink?: string | null;
  favoriteNumber?: number | null;
  gender?: StoreGender | null;
  gymRole?: string | null;
  hometown?: string | null;
  instagramFollowing?: number | null;
  instagramLink?: string | null;
  isBirthDayKnown?: boolean | null;
  isBirthMonthKnown?: boolean | null;
  leagueId?: string | null;
  organizationType?: OrganizationType | null;
  otherCollege?: string | null;
  otherLeague?: string | null;
  otherTeam?: string | null;
  socialMediaLink?: string | null;
  state?: string | null;
  storeName?: string | null;
  storeTitle?: string | null;
  teamId?: string | null;
  tiktokFollowing?: number | null;
  tiktokLink?: string | null;
  twitterFollowing?: number | null;
  twitterLink?: string | null;
  website?: string | null;
  youtubeFollowing?: number | null;
  youtubeLink?: string | null;
}

export interface StoreEditInput {
  storeDetails?: StoreDetailsInput | null;
  storeId?: string | null;
  userDetails?: UserDetailsInput | null;
}

export interface StoreFbSignInInput {
  accessToken: string;
  role: UserRole;
}

export interface StoreGetInput {
  id?: string | null;
  slug?: string | null;
}

export interface StoreGoogleSignInInput {
  code: string;
  redirectUrl?: string | null;
  role: UserRole;
}

export interface StoreImageInput {
  id?: string | null;
  image: any;
}

export interface StoreRejectMerchDesignInput {
  designRequestId: string;
  feedback: string;
}

export interface StoreTiktokSignUpV2Input {
  code: string;
  redirectUrl: string;
  role: UserRole;
}

export interface StoreTwitterSignUpInput {
  oauth_token: string;
  oauth_verifier: string;
  role: UserRole;
}

export interface StoresByHashtagInput {
  direction?: SortDirection | null;
  hashtagId?: number | null;
  hashtagName?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StoresOrderBy | null;
  status?: StoreStatus | null;
  storeRoles?: UserRole[] | null;
}

export interface StoresFilterInput {
  areaOfInfluenceIds?: string[] | null;
  direction?: SortDirection | null;
  hasActiveAma?: boolean | null;
  hasActiveExperiences?: boolean | null;
  hasActiveMerch?: boolean | null;
  hasActiveProducts?: boolean | null;
  hasActiveStreams?: boolean | null;
  hasAreaOfInfluence?: boolean | null;
  hasBirthDate?: boolean | null;
  hasPsychosocialKeywords?: boolean | null;
  hashtagIds?: number[] | null;
  id?: string | null;
  isShoppingEnabled?: boolean | null;
  isStreamer?: boolean | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StoresOrderBy | null;
  psychosocialKeywordsIds?: string[] | null;
  searchTerm?: string | null;
  slug?: string | null;
  sportId?: number | null;
  sports?: number[] | null;
  status?: StoreStatus[] | null;
  storeIds?: string[] | null;
  storeName?: string | null;
}

export interface StreamCreateInput {
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  image?: any | null;
  imageFileKey?: string | null;
  isFree?: boolean | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  requestedPrice?: number | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  timeZone?: TimeZoneInput | null;
}

export interface StreamCreateSponsorsInput {
  id?: string | null;
  imageFile?: any | null;
  imageFileKey?: string | null;
  logo?: string | null;
  name: string;
  promoMessage?: string | null;
}

export interface StreamFilterInput {
  direction?: SortDirection | null;
  haveSponsor?: boolean | null;
  id?: string | null;
  ids?: string[] | null;
  limit?: number | null;
  moderatorId?: string | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamOrderBy | null;
  price?: FromToFilter | null;
  priceCategories?: StreamPriceCategory[] | null;
  scheduleDate?: FromToDatesFilter | null;
  searchTerm?: string | null;
  slug?: string | null;
  sportIds?: number[] | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  storeName?: string | null;
  storeSlug?: string | null;
  storeStatus?: StoreStatus | null;
  streamStatus?: StreamStatus[] | null;
  tzcodes?: string[] | null;
  withCopies?: boolean | null;
  withDeleted?: boolean | null;
}

export interface StreamNoteCreateInput {
  note: string;
  relativeTs?: number | null;
  streamId: string;
  streamRecordId?: number | null;
}

export interface StreamOrderAndTipsTypeInput {
  customerEmail?: string | null;
  customerName?: string | null;
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: MyTipsAndOrdersSalesOrderBy | null;
  paidAtDateRange?: FromToDatesFilter | null;
  storeId?: string | null;
}

export interface StreamOrderCreateInput {
  promocodeId?: string | null;
  streamId: string;
}

export interface StreamParticipantFilterInput {
  direction?: SortDirection | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: StreamParticipantsOrderBy | null;
  status?: StoreStatus[] | null;
  streamId: string;
}

export interface StreamParticipantInviteInput {
  streamId: string;
}

export interface StreamSalesInput {
  direction?: SortDirection | null;
  limit?: number | null;
  name?: string | null;
  offset?: number | null;
  orderBy?: StreamSalesOrderBy | null;
  scheduledDateRange?: FromToDatesFilter | null;
  storeId?: string | null;
  storeName?: string | null;
}

export interface StreamTipCreateInput {
  amount: number;
  message?: string | null;
  streamId: string;
}

export interface StreamUpdateInput {
  description: string;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  image?: any | null;
  imageFileKey?: string | null;
  isHidden?: boolean | null;
  isPPV?: boolean | null;
  mentionsInputs?: CreateMentionInput[] | null;
  moderatorId?: string | null;
  name?: string | null;
  participantIds?: string[] | null;
  repeatingTitle?: string | null;
  repeatsEveryEnum?: StreamIntervalEnum | null;
  scheduleDate?: any | null;
  scheduleDateV2?: DateV2Input | null;
  sponsors?: StreamCreateSponsorsInput[] | null;
  sportIds?: number[] | null;
  timeZone?: TimeZoneInput | null;
}

export interface SubmitCampaignOfferResultsInput {
  offerId: string;
  submissions: CampaignOfferSubmissionInput[];
}

export interface TiktokSignInV2Input {
  code: string;
  redirectUrl: string;
}

export interface TimeZoneInput {
  offset?: number | null;
  tzCode?: string | null;
}

export interface TwitterSigninInput {
  oauth_token: string;
  oauth_verifier: string;
}

export interface UnfollowStoresInput {
  storeIds: string[];
}

export interface UnhideStreamInput {
  id: string;
}

export interface UpdateAddressInput {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  company?: string | null;
  country?: string | null;
  countryCode?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  isDefault?: boolean | null;
  lastName?: string | null;
  phone?: string | null;
  phoneNumberE164?: string | null;
  state?: string | null;
  stateCode?: string | null;
  zipCode?: string | null;
}

export interface UpdateMemorabiliaInput {
  addressId?: string | null;
  auctionDateRange?: AuctionFromToInput | null;
  changedStock?: number | null;
  comment?: string | null;
  customsDescription?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  height?: number | null;
  id: string;
  images: MemorabiliaImageInput[];
  length?: number | null;
  requestedPrice: number;
  shipmentCategory: string;
  storeId: string;
  title: string;
  weight?: number | null;
  width?: number | null;
}

export interface UpdateMerchProductInput {
  customImages?: MerchCustomImageInput[] | null;
  hashtagInputs?: HashtagInput[] | null;
  id: number;
  printfulVariantIds?: number[] | null;
  productPrintFiles?: ProductPrintFileEditInputType[] | null;
  requestedProfit: number;
  stitches?: string | null;
  storeId?: string | null;
  threads?: string | null;
  title: string;
}

export interface UpdateStoreExperienceInput {
  comment?: string | null;
  description?: string | null;
  hashtagInputs?: HashtagInput[] | null;
  id: string;
  images: ExperienceImageInput[];
  numberOfUnits?: number | null;
  requestedPrice: number;
  title: string;
}

export interface UserDetailsInput {
  areaOfInfluenceIds?: string[] | null;
  email?: string | null;
  firstName?: string | null;
  hashtagIds?: number[] | null;
  hashtagInputs?: HashtagInput[] | null;
  isOrphanPage?: boolean | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
  psychosocialKeywordsIds?: string[] | null;
  redirects?: string[] | null;
  sportIds?: number[] | null;
}

export interface UserUpdateInput {
  email: string;
  firstName: string;
  id?: string | null;
  lastName: string;
  phoneNumber?: string | null;
  phoneNumberE164?: string | null;
}

export interface VariantPrintFileInput {
  key: string;
  placementType: PlacementTypes;
  printFilePlacements: ProductPrintFilePlacementInput[];
}

export interface VariantPrintfilePresignedUrlInput {
  contentType: string;
  ext: string;
}

export interface VideoCroppedArea {
  height: number;
  width: number;
  x: number;
  y: number;
  zoom: number;
}

export interface VideoCroppedInput {
  area?: VideoCroppedArea | null;
  file: any;
  type?: string | null;
}

export interface VideoEditRequestsStoreFilterInput {
  direction?: SortDirection | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: VideoEditRequestsStoreOrderBy | null;
  statuses?: VideoEditRequestStatus[] | null;
  storeId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
